
import { useContext, useEffect } from "react";
import "./Spinner.css";
import ReactLoading from "react-loading";
import { DataContext } from "~/contexts/Data";
const Spinner = ({ full }) => {
    const { darkmodeStatus } = useContext(DataContext);

    return (
        <div className={`spinner ${full ? "spinner--full" : ""}`}>
            <ReactLoading
                type="cylon"
                width={150}
                color={darkmodeStatus ? "#fff" : "#742022"}
            />
        </div>
        // <div class="spinner">
        //     <div class="loader">
        //         <div></div>
        //         <div></div>
        //         <div></div>
        //         <div></div>
        //         <div></div>
        //         <div></div>
        //         <div></div>
        //         <div></div>
        //         <div></div>
        //     </div>
        // </div>
    );
};

export default Spinner;