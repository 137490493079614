import React, { createContext, useState, useEffect, useCallback } from 'react';

// External components
import Axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import Flag from "~/components/Flags";


const axios = setupCache(Axios);

const languages = [
	{
		value: "nb-no",
		url: "/",
		prefix: "",
		label: "Norsk",
		short: "NO",
		path: "/src/locales/no.json",
		icon: <Flag code={"nb-no"} />,
	},
	{
		value: "en",
		url: "/en",
		label: "English",
		short: "EN",
		path: "/src/locales/en.json",
		icon: <Flag code={"en-us"} />,
		prefix: "/en/"
	},
];
export const DataContext = createContext();
export const DataProvider = ({ children }) => {
	const useStickyState = (defaultValue, key) => {
		const [value, setValue] = useState(() => {
			const stickyValue = window.localStorage.getItem(key);
			return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
		});
		useEffect(() => {
			window.localStorage.setItem(key, JSON.stringify(value));
		}, [key, value]);
		return [value, setValue];
	};
	const [darkmodeStatus, setDarkmodeStatus] = useStickyState(
		true,
		'darkmodeEnabled'
	);
	// IF click outside
	/* Used like this:

	const innerRef = useOuterClick((ev) => {
		showMenu(false);
	});
	<div ref={innerRef}></div>
	*/

	/* ********************************** */
	/*                DATA                */
	/* ********************************** */
	const [data, setData] = useState(null);
	const [routes, setRoutes] = React.useState();
	const [currentRoute, setCurrentRoute] = React.useState();
	const addValuesToChild = (parent, child) => {
		let obj = { parentKey: parent.key };
		// if (child.type.toLowerCase() === "avdeling") {
		//     obj["ansatteny"] = child?.ansatte?.map((ansatt) => {
		//         return {
		//             ...ansatt,
		//             url: ansatt.url.replace(/(ansatte)/, `$1/${child.slug}`),
		//         };
		//     });
		// }
		return { ...child, ...obj };
	};
	const [parentRoute, setParentRoute] = useState(null);
	const [noMoreRoutes, setNoMoreRoutes] = useState(false);
	// const updateParentRoute = React.useCallback(() => {
	//     let r = routes
	//         ?.filter((p) => p.key === currentRoute.key)
	//         ?.map((p) => p);
	//     setParentRoute(r);
	// }, [routes, currentRoute]);
	useEffect(() => {
		if (noMoreRoutes && routes && currentRoute) {
			let r = routes?.filter((route) =>
				route.children?.filter((child) => child.key === currentRoute.key)
					.length > 0
					? true
					: false
			);
			if (r.length > 0) {
				setParentRoute(r[0]);
			} else {
				// Reset if not on a subroute
				setParentRoute(null);
			}
		}
	}, [noMoreRoutes, routes, currentRoute]);

	const evaluateChildNodes = React.useCallback((node) => {
		// If children of root has children
		if (node.children?.length > 0) {
			// Update rooutes by adding children

			setRoutes((routes) => [
				...routes,
				...node.children.map((child) => addValuesToChild(node, child)),
			]);
			// Run loop function on children
			node.children.forEach((singleNode) => {
				evaluateChildNodes(singleNode);
			});
		} else {
			// No children, done with loop
			setNoMoreRoutes(true);
		}
	}, []);
	useEffect(() => {
		if (data?.children) {
			setRoutes(
				(routes) =>
					Array.isArray(routes) // If routes is array
						? [
							...routes,
							...data?.children.map((child) => addValuesToChild(data, child)),
						] // Merge both routes and children into new array
						: [
							data,
							...data?.children.map((child) => addValuesToChild(data, child)),
						] // If routes is not array, initiate array with root and children
			);

			// Run loop function on children
			data?.children.forEach((singleNode) => {
				evaluateChildNodes(singleNode);
			});
		} else {
			setRoutes(data);
		}
	}, [data, evaluateChildNodes]);
	const [lang, setLang] = useStickyState(languages[0], "lang");
	useEffect(() => {
		// Get data
		// https://talentech-docs.atlassian.net/wiki/spaces/DOCS/pages/172982302/Common+properties+for+RESTFul+apis
		// Replace test data with this when Umbraco is up and running
		// console.log(`/headless/data/${lang.value}`);
		if (lang) {
			axios({
				method: 'get',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'Access-Control-Allow-Origin': '*',
					mode: 'no-cors',
					// cache: "no-cache",
				},
				url: `/headless/data/${lang.value}`,
			})
				.then((response) => {
					let d = { ...response.data, isRoot: true };
					setData(d);
				})
				.catch((e) => {
					console.error(e);
				});
		}
	}, [lang]);


	// Dev stuff
	const log = (d) => <pre> {JSON.stringify(d, null, 1)}</pre>;
	const [devStatus, toggleDevStatus] = useStickyState(false, 'devmode');

	const [useForPositions, setUseForPositions] = useState(null);

	useEffect(() => {
		if (routes) {
			let filtered = [...routes].filter((p) => p.useForPositions === 'True')[0];
			setUseForPositions(filtered);
		}
	}, [routes]);
	const [isOpen, setOpen] = useState(false);
	const updateOpen = useCallback(
		(bool) => {
			setOpen(bool);
		},
		[setOpen],
	);

	return (
		<DataContext.Provider
			value={{
				data,
				routes,
				log,
				devStatus,
				toggleDevStatus,
				currentRoute,
				setCurrentRoute,
				useForPositions,
				parentRoute,
				setParentRoute, isOpen, updateOpen,
				lang,
				setLang,
				languages,
				darkmodeStatus,
				setDarkmodeStatus
			}}
		>
			{children}
		</DataContext.Provider>
	);
};
