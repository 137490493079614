import React from 'react';
import "./Flags.css";
const Flags = ({ code }) => {
    if (code === "nb-no") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 22 16" width="22" height="16">
                <rect width="22" height="16" fill="#ba0c2f"></rect>
                <path d="M0,8h22M8,0v16" stroke="#fff" strokeWidth="4"></path>
                <path d="M0,8h22M8,0v16" stroke="#00205b" strokeWidth="2"></path>
            </svg>
        );
    }
    if (code === "en-us") {
        return (
            <svg className="flag" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 30" width="22" height="16" >
                <clipPath id="t">
                    <path d="M25,15h25v15zv15h-25zh-25v-15zv-15h25z"></path>
                </clipPath>
                <path d="M0,0v30h50v-30z" fill="#012169"></path>
                <path d="M0,0 50,30M50,0 0,30" stroke="#fff" strokeWidth="6"></path>
                <path d="M0,0 50,30M50,0 0,30" clipPath="url(#t)" stroke="#C8102E" strokeWidth="4"></path>
                <path d="M-1 11h22v-12h8v12h22v8h-22v12h-8v-12h-22z" fill="#C8102E" stroke="#FFF" strokeWidth="2"></path>
            </svg>
        );
    }
};

export default Flags;