import { createContext, useEffect, useState, useCallback } from 'react';

// External components
import axios from 'axios';
export const JobsContext = createContext();
// import newData from "./hr-data-new.json";
const JobsProvider = ({ children }) => {
	// GET HR DATA
	const [hrData, setHrData] = useState(null);
	const [jobs, setJobs] = useState(null); // JOBS from HR DATA
	const [jobsArr, setJobsArr] = useState(null);
	const [jobCoords, setJobCoords] = useState(null);
	const [locationData, setLocationData] = useState(null);

	// Fetch JOBS
	const loopThroughJobs = useCallback((data) => {
		if (data) {
			let parsed = JSON.parse(JSON.stringify(data));
			setHrData(parsed);
			let coordsObj = {};
			let missingCoordinates = [];
			if (parsed) {
				let unformattedJobs = parsed?.Items;
				let jobsArr = [];
				unformattedJobs?.forEach((j) => {
					let ads = [];
					j.Advertisements.forEach((ad) => {
						ads.push({
							title: ad.Name,
							richText: ad.Content,
							companyLogo: ad.ImageUrl,
							introText: ad.Introduction,
						});
					});

					// Set coords
					let coords = j.WorkPlaceCoordinates;
					if (coords) {
						let lat = j.WorkPlaceCoordinates.split(',')[0]?.replace(/ /g, "");
						let lon = j.WorkPlaceCoordinates.split(',')[1]?.replace(/ /g, "");
						coordsObj[j.Id] = {
							lat,
							lon,
							id: j.Id,
							// title: j.Name
						};
					}
					else {
						missingCoordinates.push({
							id: j.Id,
							// title: j.Name
						});
					}
					const formatWeirdDate = (d) => {
						if (d) {
							let dateString = d.substr(6);
							let currentTime = new Date(parseInt(dateString));
							let month = currentTime.getMonth() + 1;
							let day = currentTime.getDate();
							let year = currentTime.getFullYear();
							let date = day + '.' + month + '.' + year;
							if (date === '1.1.1') {
								return 'snarest';
							} else {
								return date;
							}
						}
					};
					let publishedDateFormatted = formatWeirdDate(j.Created);
					let applicationDueFormatted = formatWeirdDate(j.ApplicationDue);
					let posType = j.positionType;
					// if (j.Name === "Business Controller - RSA") {
					// 	posType = "Leder"
					// }
					jobsArr.push({
						title: j.Name,
						thumbnail: j.Advertisements[0]?.ImageUrl,
						publishedDate: j.Created,
						publishedDateFormatted: publishedDateFormatted,
						workPlace: j.WorkPlace,
						applicationDue: j.ApplicationDue,
						applicationDueFormatted: applicationDueFormatted,
						ads: ads,
						positionType: posType,
						coordinates: coords,
						users: j.Users,
						hidePageTitle: true,
						showApplyBtn: j.ShowApplyButton,
						applyUrl: j.ApplicationFormUrlSecure,
						id: j.Id,
					});
				});
				let jobCoords = Object.values(coordsObj);
				setJobCoords(jobCoords);
				setJobsArr(jobsArr);
			}

			// console.log(missingCoordinates);
		}
	}, []);
	useEffect(() => {
		axios({
			method: 'get',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Access-Control-Allow-Origin': '*',
				mode: 'no-cors',
			},
			url: '/headless/hrmanager?take=100',
		})
			.then((response) => {
				// console.log(response);
				loopThroughJobs(response.data);
				// loopThroughJobs(newData);
			})
			.catch((e) => {
				console.error(e);
			});
	}, [loopThroughJobs]);

	// Fetch LOCATIONDATA based on COORDINATES from JOBS
	const fetchCoordsData = useCallback((coordsArray) => {
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		let raw = JSON.stringify(coordsArray);

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow"
		};

		fetch("/headless/geocode", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				setLocationData(result);
			})
			.catch((error) => console.error(error));


	}, []);
	useEffect(() => {
		if (jobCoords) {
			fetchCoordsData(jobCoords);
		}
	}, [jobCoords, fetchCoordsData]);

	// // Add LOCATIONDATA to JOBS
	const addCoords = useCallback((jobsArr, coordsData) => {
		let jobsWithLocationData = [];
		jobsArr?.forEach((job) => {
			let obj = {};
			let locData = Array.isArray(coordsData) ? coordsData?.filter(
				(c) => `${c.query.lat}, ${c.query.lon}` === job.coordinates
			)[0] : undefined;
			if (locData !== undefined) {
				let postCodeKey = null;
				if (locData.postcode?.length === 3) {
					postCodeKey = '0';
				} else {
					postCodeKey = locData.postcode?.substring(0, 1);
				}
				locData.postCodeKey = postCodeKey;
				let k = postCodeKey;
				locData.postalAreaNorway = '';
				let inArr = (arr) =>
					arr.filter((item) => k === item)?.length > 0 ? true : false;
				if (inArr(['0', '1', '2', '3'])) {
					locData.postalAreaNorway = 'Øst';
				}
				if (inArr(['4'])) {
					locData.postalAreaNorway = 'Sør';
				}
				if (inArr(['5', '6'])) {
					locData.postalAreaNorway = 'Vest';
				}
				if (inArr(['7', '8', '9'])) {
					locData.postalAreaNorway = 'Midt/Nord';
				}
				// if (inArr(['9'])) {
				// 	locData.postalAreaNorway = 'Nord';
				// }
				obj = { ...job, locationData: locData };
			} else {
				obj = { ...job };
			}
			jobsWithLocationData?.push(obj);
		});
		setJobs(jobsWithLocationData);
	}, []);
	useEffect(() => {
		if (jobsArr && locationData) {
			addCoords(jobsArr, locationData);
		}
	}, [jobsArr, locationData, addCoords]);

	// Exported props
	// JOB props
	const jobProps = {
		jobsArr, jobs, jobCoords, hrData
	};

	return <JobsContext.Provider value={jobProps}>{children}</JobsContext.Provider>;
};
export default JobsProvider;
