import { lazy, useCallback, useContext, useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

// Contexts
import { DataContext } from '~/contexts/Data';

// External components
// import CookieConsent, { resetCookieConsentValue } from 'react-cookie-consent';
import Hotkeys from 'react-hot-keys';
import { JobsContext } from '~/contexts/JobsContext';

// Internal components
import slugify from 'slugify';
import Spinner from './components/Spinner';

const DevMode = lazy(() => import('~/components/Dev/DevMode'));
const NotFound = lazy(() => import('~/components/NotFound'));
const Header = lazy(() => import('~/components/Layout/Header'));
const Page = lazy(() => import('~/components/Layout/Page'));
const Position = lazy(() => import('~/components/Positions/Position'));
const InternalOrExternalLink = lazy(() => import('~/components/InternalOrExternalLink'));
const DarkMode = lazy(() => import('~/components/Layout/DarkMode'));
const Footer = lazy(() => import('~/components/Layout/Footer'));

// const Header = React.lazy(() => import('~/components/Layout/Header'));
// const Footer = React.lazy(() => import('~/components/Layout/Footer'));

const P = ({ children }) => {
	return (
		<div className={`page-wrapper `}>
			<Header />
			{children}
			<Footer />
		</div>
	);
};
const App = () => {
	const { data, routes, useForPositions, devStatus, toggleDevStatus, currentRoute, setLang, languages, lang, darkmodeStatus } = useContext(DataContext);
	useEffect(() => {
		if (darkmodeStatus) {
			document.body.style.backgroundColor = "#170c0c";
		} else {
			document.body.style.backgroundColor = "#fff";
		}
	}, [darkmodeStatus]);
	const { jobs } = useContext(JobsContext);
	const location = useLocation();
	useEffect(() => {
		console.log(
			'%cPress: "alt" + "shift" + "r" to reset cookie banner',
			'color:green;font-weight:bold;border:1px solid black;padding: 1rem;'
		);
	}, []);
	useEffect(() => {
		function scrollToTargetAdjusted(element) {
			element.scrollIntoView({
				// behavior: "smooth",
				// block: "start",
				// inline: "start",
			});
		}
		let hash = location.hash;
		if (hash) {
			let element = document.getElementById(hash.replace('#', ''));
			if (element) {
				scrollToTargetAdjusted(element);
			} else {
				window.scrollTo(0, 0);
			}
			// document
			//     .getElementById(hash.replace("#", ""))
			//     .scrollIntoView({ behavior: "smooth" });
		} else {
			window.scrollTo(0, 0);
		}
	}, [location]);

	const { pathname } = useLocation();
	const updateLang = useCallback(
		(v) => {

			setLang(v);
		},
		[setLang],
	);

	const resetLang = useCallback(
		() => {
			if (lang !== languages[0]) {
				updateLang(languages[0]);
			}
		},
		[updateLang, languages, lang],
	);

	const [langIsSet, setLangIsSet] = useState(null);
	useEffect(() => {
		if (pathname && languages) {
			let pathUrl = pathname?.split("/")[1];
			let pathLang = languages?.filter(la => la.url === `/${pathUrl}`);
			if (pathLang?.length > 0) {
				updateLang(pathLang[0]);
			} else {
				resetLang();
			}
			setLangIsSet(true);
		}
	}, [pathname, languages, updateLang, resetLang]);

	return (
		<div >
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ visibility: "hidden", pointerEvents: "none", display: "none" }}>
				<defs>
					<filter id="f1" x="-20%" y="-20%" width="160%" height="160%">
						<feGaussianBlur id="b1" result="blurOut" in="SourceGraphic" stdDeviation="1"></feGaussianBlur>
						<feComposite
							id="c1"
							operator="arithmetic"
							k1={getComputedStyle(document.documentElement).getPropertyValue('--sharpen-k1')}
							k2={getComputedStyle(document.documentElement).getPropertyValue('--sharpen-k2')}
							k3={getComputedStyle(document.documentElement).getPropertyValue('--sharpen-k3')}
							k4={getComputedStyle(document.documentElement).getPropertyValue('--sharpen-k4')}
							in="SourceGraphic" in2="blurOut"></feComposite>
					</filter>
				</defs>
			</svg>
			<DarkMode />
			<Hotkeys
				keyName='alt+shift+g'
				allowRepeat={true}
				onKeyDown={() =>
					window
						.open(`https://github.com/aksellsor/07_cra_headvisor/actions`, '_blank')
						.focus()
				}
			></Hotkeys>
			{(currentRoute) && (
				<Hotkeys
					keyName="alt+shift+u"
					allowRepeat={true}
					onKeyDown={() => {
						if (window.location.hostname === "localhost") {
							window
								.open(
									`${currentRoute
										? `https://hv.demoside.no/umbraco#/content/content/edit/${currentRoute?.id
										}`
										: "https://hv.demoside.no/umbraco"
									}`,
									"_blank"
								)
								.focus();
						} else {

							window
								.open(
									`${currentRoute
										? `/umbraco#/content/content/edit/${currentRoute?.id
										}`
										: "/umbraco"
									}`,
									"_blank"
								)
								.focus();
						}
					}
					}
				/>
			)}
			<Hotkeys
				keyName='ctrl+shift+d'
				onKeyDown={() => toggleDevStatus(!devStatus)}
			></Hotkeys>
			{/* <Hotkeys
				keyName='alt+shift+r'
				onKeyDown={() => {
					resetCookieConsentValue('acceptCookies');
					window.location.reload();
				}}
			></Hotkeys> */}
			{/* <CookieConsent
				location='bottom'
				buttonText='Jeg forstår'
				cookieName='acceptCookies'
				style={{
					background: '#2B373B',
					width: 'max-content',
					display: 'flex',
					bottom: '0 env()',
					justifyContent: 'center',
					flexWrap: 'wrap',
					maxWidth: '100vw',
					alignItems: 'center',
					padding: '0.5rem',
					paddingBottom: '1.5rem',
					paddingLeft: '1.5rem',
					paddingRight: '1.5rem',
					left: '50%',
					transform: 'translateX(-50%)',
				}}
				disableButtonStyles
				buttonClasses='btn btn-primary rounded-pill px-4 mb-4 mb-sm-2'
			>
				<p className='mb-0' style={{ maxWidth: '40ch' }}>
					Denne nettsiden bruker informasjonskapsler{' '}
					<span style={{ opacity: 0.5 }}>(cookies)</span> for å bedre
					brukeropplevelsen.{' '}
					{data?.cookiesPage?.url && (
						<InternalOrExternalLink
							to={data?.cookiesPage?.url}
							target={data?.cookiesPage?.target}
							style={{
								color: '#cbacad',
								textDecoration: 'underline',
							}}
							className='d-inline-block d-sm-inline'
						>
							{data?.cookiesPage?.label}
						</InternalOrExternalLink>
					)}
				</p>
			</CookieConsent> */}
			{devStatus && <DevMode />}
			{data && routes ? (
				<P>
					<Routes>
						{useForPositions?.url &&
							jobs?.map((item) => (

								<Route
									key={item.id + '-hr-data-positionList'}
									path={`${useForPositions?.url}/${slugify(item.title)}`}
									element={
										<Page page={item}>
											<div className='my-5'>
												<Position content={item} />
											</div>
										</Page>
									}
								/>
							))}
						{routes?.map((page, index) => (
							<Route
								key={index + 'route'}
								path={page.url}
								element={<Page page={page} />}
							/>
						))}
						<Route
							path='*'
							element={<NotFound />}
						// key={"404"}
						/>
					</Routes>
				</P>
			) :
				<>
					<Spinner full />
				</>
			}
		</div>
	);
};

export default App;
