import React, { createContext, useState, useEffect, useRef } from 'react';

// External components
import parse from 'html-react-parser';
import { useScrollDirection } from 'react-use-scroll-direction';

export const HelpersContext = createContext();
export const HelpersProvider = ({ children }) => {
	// Check if user is using safari
	const [isSafari, setIsSafari] = useState(false);
	useEffect(() => {
		let safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		if (safari) {
			setIsSafari(true);
		} else {
			setIsSafari(false);
		}
	}, []);

	// Screen width
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const updateScreenWidth = () => {
		setScreenWidth(window.innerWidth);
	};
	useEffect(() => {
		window.addEventListener('resize', updateScreenWidth);
		return () => {
			window.removeEventListener('resize', updateScreenWidth);
		};
	}, []);

	// Screen height
	const [screenHeight, setScreenHeight] = useState(window.innerHeight);
	const updateScreenHeight = () => {
		setScreenHeight(window.innerHeight);
	};
	useEffect(() => {
		window.addEventListener('resize', updateScreenHeight);
		return () => {
			window.removeEventListener('resize', updateScreenWidth);
		};
	}, []);

	// Check if touch
	const [isTouch, setIsTouch] = useState(true);
	useEffect(() => {
		if ('ontouchstart' in document.body) {
			setIsTouch(true);
		} else {
			setIsTouch(false);
		}
	}, []);

	// Check if user is dragging
	const [isDrag, setIsDrag] = useState(true);
	useEffect(() => {
		const delta = 6;
		let startX;
		let startY;
		const onMouseDown = (event) => {
			startX = event.pageX;
			startY = event.pageY;
		};
		const onMouseUp = (event) => {
			const diffX = Math.abs(event.pageX - startX);
			const diffY = Math.abs(event.pageY - startY);

			if (diffX < delta && diffY < delta) {
				setIsDrag(false);
			} else {
				setIsDrag(true);
			}
		};

		document.addEventListener('mousedown', onMouseDown);
		document.addEventListener('mouseup', onMouseUp);
		return () => {
			document.removeEventListener('mousedown', onMouseDown);
			document.removeEventListener('mouseup', onMouseUp);
		};
	}, []);

	// Get time since date
	const timeSince = (date) => {
		let formatted = new Date(
			date
				.toString()
				.replace(/\./g, '/')
				.replace(/(\d+[/])(\d+[/])/, '$2$1')
		);
		var seconds = Math.floor((new Date() - formatted) / 1000);

		var interval = seconds / 31536000;

		if (interval > 1) {
			return Math.floor(interval) + ' år';
		}
		interval = seconds / 2592000;
		if (interval > 1) {
			let d = Math.floor(interval);
			return d + ` måned${d > 1 ? 'er' : ''}`;
		}
		interval = seconds / 86400;
		if (interval > 1) {
			let d = Math.floor(interval);
			return d + ` dag${d > 1 ? 'er' : ''}`;
		}
		interval = seconds / 3600;
		if (interval > 1) {
			let d = Math.floor(interval);
			return d + ` tim${d > 1 ? 'er' : ''}`;
		}
		interval = seconds / 60;
		if (interval > 1) {
			let d = Math.floor(interval);
			return d + ` minutt${d > 1 ? 'er' : ''}`;
		}
		let d = Math.floor(interval);
		return d + ` sekund${d > 1 ? 'er' : ''}`;
	};

	const useOuterClick = (callback) => {
		const callbackRef = useRef(); // initialize mutable callback ref
		const innerRef = useRef(); // returned to client, who sets the "border" element

		// update callback on each render, so second useEffect has most recent callback
		useEffect(() => {
			callbackRef.current = callback;
		});
		useEffect(() => {
			function handleClick(e) {
				if (
					innerRef.current &&
					callbackRef.current &&
					!innerRef.current.contains(e.target)
				)
					callbackRef.current(e);
			}
			document.addEventListener('click', handleClick);
			return () => document.removeEventListener('click', handleClick);
		}, []); // no dependencies -> stable click listener

		return innerRef; // convenience for client (doesn't need to init ref himself)
	};

	// Scroll direction
	const [scrollDirection, setScrollDirection] = useState();
	const { isScrollingUp, isScrollingDown } = useScrollDirection();

	React.useEffect(() => {
		isScrollingDown && setScrollDirection('down');
		isScrollingUp && setScrollDirection('up');
	}, [isScrollingDown, isScrollingUp]);
	const [scrolled, setScrolled] = useState(window.scrollY);
	const updateScroll = () => {
		setScrolled(window.scrollY);
	};
	useEffect(() => {
		window.addEventListener('scroll', updateScroll);
		return () => document.removeEventListener('cscrolllick', updateScroll);
	}, []);

	var DateDiff = {
		inDays: function (d1, d2) {
			var t2 = d2.getTime();
			var t1 = d1.getTime();

			return parseInt((t2 - t1) / (24 * 3600 * 1000));
		},

		inWeeks: function (d1, d2) {
			var t2 = d2.getTime();
			var t1 = d1.getTime();

			return parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
		},

		inMonths: function (d1, d2) {
			var d1Y = d1.getFullYear();
			var d2Y = d2.getFullYear();
			var d1M = d1.getMonth();
			var d2M = d2.getMonth();

			return d2M + 12 * d2Y - (d1M + 12 * d1Y);
		},

		inYears: function (d1, d2) {
			return d2.getFullYear() - d1.getFullYear();
		},
	};
	const getDaysUntil = (d) => {
		let dateString = d.substr(6);
		let currentTime = new Date(parseInt(dateString));
		let today = new Date();
		let daysUntil = DateDiff.inDays(today, currentTime);
		return daysUntil;
	};
	const getTimeUntil = (d) => {
		let dateString = d.substr(6);
		let currentTime = new Date(parseInt(dateString));
		let today = new Date();
		let daysUntil = DateDiff.inDays(today, currentTime);
		let weeksUntil = DateDiff.inWeeks(today, currentTime);
		let monthsUntil = DateDiff.inMonths(today, currentTime);
		let yearsUntil = DateDiff.inYears(today, currentTime);
		if (yearsUntil > 0) {
			return `${yearsUntil} år`;
		} else if (monthsUntil > 0) {
			return `${monthsUntil} mnd`;
		} else if (weeksUntil > 0) {
			return `${weeksUntil} uker`;
		} else if (daysUntil > 0) {
			return `${daysUntil} dager`;
		} else {
			return 'snarest';
		}
	};
	const formatWeirdDate = (d) => {
		if (d) {
			let dateString = d.substr(6);
			let currentTime = new Date(parseInt(dateString));
			let month = currentTime.getMonth() + 1;
			let day = currentTime.getDate();
			let year = currentTime.getFullYear();
			let date = day + '.' + month + '.' + year;
			if (date === '1.1.1') {
				return 'snarest';
			} else {
				return date;
			}
		}
	};

	return (
		<HelpersContext.Provider
			value={{
				isSafari, // Check if browser is Safari
				screenWidth, // Get screen width, with resize listener
				screenHeight, // Get screen height, with resize listener
				isDrag, // Check if user is dragging
				isTouch, // Check if device is touch
				timeSince, // Get time since date
				useOuterClick, // If clicking outside
				scrollDirection,
				parse, // Used instead of dangerouslySetInnerHTML, {parse("<div/>")}
				formatWeirdDate,
				scrolled,
				getTimeUntil,
				getDaysUntil,
			}}
		>
			{children}
		</HelpersContext.Provider>
	);
};
